import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

export default function Main(props) {
  const today = moment().format("YYYY-MM-DD");
  const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
  const token = props.auth;
  const [av, setAv] = useState([0, 0]);
  const [registered, setRegistered] = useState([[], []]);
  const [loading, setLoading] = useState([false, false]);

  const register = useCallback(async (num) => {
    await axios.post(
      "https://parking-api.crayon.lt/register",
      // "http://localhost:3333/register",
      {
        date: num == 0 ? today : tomorrow,
        token,
      }
    );

    fetchRegistered();
  });

  const cancelRegistration = useCallback(async (day) => {
    const stringDay = `${day}`;
    await axios.post(
      "https://parking-api.crayon.lt/cancel-registration",
      // "http://localhost:3333/cancel-registration",
      {
        day: stringDay,
        token,
      }
    );

    fetchRegistered();
  });

  const getRegistered = useCallback(async (date) => {
    const data = await axios.post(
      "https://parking-api.crayon.lt/registrations",
      // "http://localhost:3333/registrations",
      {
        token,
        date,
      }
    );

    return data.data;
  });

  const fetchRegistered = useCallback(async () => {
    setLoading([true, true]);
    const regToday = await getRegistered(today);
    const regTomorrow = await getRegistered(tomorrow);
    setRegistered([regToday, regTomorrow]);
    setAv([11 - regToday.length, 11 - regTomorrow.length]);

    setTimeout(() => {
      setLoading([false, false]);
    }, 500);
  });

  useEffect(() => {
    fetchRegistered();
  }, []);

  return (
    <div>
      {[0, 1].map((el) => (
        <div key={el} className="border-2 border-slate-50 my-5 p-5 rounded-lg">
          {loading[el] == false ? (
            <>
              <div className="text-xl border-1">
                {el == 0 ? "Today" : "Tomorrow"}
              </div>
              <div>
                Out of <span className="font-bold">11</span> available parking
                spots <span className="font-bold">{av[el]}</span> are available
              </div>
              <div>Registered employees:</div>
              <ul className="list-disc m-5">
                {registered[el].map((reg, index) => (
                  <li key={index}>{reg.User.name}</li>
                ))}
              </ul>
              <button
                className="rounded-lg bg-slate-400 text-slate-50 py-1 px-3"
                onClick={() => register(el)}
              >
                Register {el == 0 ? "Today" : "Tomorrow"}
              </button>
              &nbsp;
              <button
                className="rounded-lg bg-red-400 text-slate-50 py-1 px-3"
                onClick={() => cancelRegistration(el)}
              >
                Cancel Registration {el == 0 ? "Today" : "Tomorrow"}
              </button>
            </>
          ) : (
            <>
              <img
                src="refresh.svg"
                alt="loader"
                className="animate-spin h-[100px] w-[100px]"
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
}
