import { useCallback, useEffect, useState } from "react";
import "./App.css";
import Main from "./Main";
import axios from "axios";

function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [repeat, setRepeat] = useState("");
  const [info, setInfo] = useState("");

  useEffect(() => {
    async function validate() {
      try {
        const token = localStorage.getItem("token");

        const data = await axios.post(
          "https://parking-api.crayon.lt/validate",
          // "http://localhost:3333/validate",
          {
            token,
          }
        );

        setAuth(token);
      } catch (ex) {
        localStorage.removeItem("token");
        setAuth();
      }
    }

    validate();
  }, []);

  const logout = useCallback(() => {
    setAuth();
    localStorage.removeItem("token");
  });

  const changePassword = useCallback(async () => {
    try {
      if (newPass == repeat) {
        const token = localStorage.getItem("token");

        await axios.post("https://parking-api.crayon.lt/change-password", {
          token,
          password: newPass,
        });

        setInfo("Changed");
        setTimeout(() => {
          setChangePass(false);
        }, 5000);
      } else {
        setInfo("Passwords don't match");
      }
    } catch (error) {
      setInfo("Error");
    }
  }, [newPass, repeat]);

  const login = useCallback(async () => {
    try {
      setLoading(true);
      const data = await axios.post(
        "https://parking-api.crayon.lt/login",
        // "http://localhost:3333/login",
        {
          email,
          password,
        }
      );

      setAuth(data.data.token);
      localStorage.setItem("token", data.data.token);

      setTimeout(() => {
        setLoading(false);
      }, 300);
    } catch (ex) {
      localStorage.removeItem("token");
      setAuth(null);
      setLoading(false);
    }
  }, [email, password]);
  return (
    <>
      <div className="bg-slate-800 w-screen min-h-screen text-slate-50 p-2">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div className="flex">
              <img
                src="logo.svg"
                alt="logo"
                className="inline h-[50px] w-[100px]"
              />
              <h1 className="inline pl-2 leading-[50px] text-[15px]">
                Parking
              </h1>
            </div>
            <div className="flex justify-center align-middle">
              {auth && (
                <button
                  className="rounded-lg bg-[#ff6a4c] text-slate-50 py-1 px-3 mr-2"
                  onClick={() => {
                    setChangePass(true);
                    setInfo("");
                  }}
                >
                  Change password
                </button>
              )}
              <button
                className="rounded-lg bg-[#ff6a4c] text-slate-50 py-1 px-3"
                onClick={logout}
              >
                Log out
              </button>
            </div>
          </div>
          <div className="border-2 border-slate-50 my-5 p-5 rounded-lg">
            Rules:
            <ul className="list-disc m-5">
              <li>
                You <u>must</u> register for parking if you plan to park your
                car in the office parking lots;
              </li>
              <li>
                There are users with priority who can kick out users registered
                for parking without priority. This can only happen before the
                cut-off time, which is 06:00;
              </li>
              <li>
                Priority users can't choose who they kick out. The non-priority
                user being kicked out is the last one that has registered at the
                specified date. The kicked out user will recieve an email;
                notification (WIP)
              </li>
              <li>
                If a user has registered for parking but will not use the spot,
                user must cancel the registration;
              </li>
              <li>
                Guest user (Svečias) is avaiable for everyone to use, for when
                there's a guest arriving. It has priority.
              </li>
              <li>
                Reserved parking spot numbers are: 025, 026, 027, 028, 029, 030,
                069, 070, 071, 072.
              </li>
            </ul>
          </div>
          {loading == false ? (
            <>
              {auth ? (
                <>
                  {changePass ? (
                    <>
                      <div>{info}</div>
                      <div>New Password</div>

                      <input
                        className="rounded-lg bg-slate-400 text-slate-50 py-1 px-3"
                        type="password"
                        placeholder="New Password"
                        value={newPass}
                        onChange={(e) => setNewPass(e.currentTarget.value)}
                      />
                      <br />
                      <br />
                      <div>Repeat New Password</div>

                      <input
                        className="rounded-lg bg-slate-400 text-slate-50 py-1 px-3"
                        type="password"
                        placeholder="Repeat New Password"
                        value={repeat}
                        onChange={(e) => setRepeat(e.currentTarget.value)}
                      />
                      <br />

                      <br />

                      <button
                        className="rounded-lg bg-[#ff6a4c] text-slate-50 py-1 px-3 mr-2"
                        onClick={() => setChangePass(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="rounded-lg bg-[#ff6a4c] text-slate-50 py-1 px-3 mr-2"
                        onClick={() => changePassword()}
                      >
                        Change password
                      </button>
                    </>
                  ) : (
                    <>
                      <Main auth={auth} />
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="my-3">
                    <div>Email</div>
                    <input
                      className="rounded-lg bg-slate-400 text-slate-50 py-1 px-3"
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </div>
                  <div className="my-3">
                    <div>Password</div>
                    <input
                      className="rounded-lg bg-slate-400 text-slate-50 py-1 px-3"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                  </div>
                  <div className="my-3">
                    <button
                      className="rounded-lg bg-[#ff6a4c] text-slate-50 py-1 px-3"
                      onClick={login}
                    >
                      Log in
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <img
                src="refresh.svg"
                alt="loader"
                className="animate-spin h-[100px] w-[100px]"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default App;
